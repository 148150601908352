const isDevelopment = false;
const useFullUrl = false;

export const config = {
  isDevelopment: isDevelopment,
  apiUrl: isDevelopment
    ? 'http://127.0.0.1:5000'
    : useFullUrl
      ? '/api/v1'
      : 'https://manage.woofles.co.nz/api/v1',
};