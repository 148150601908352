import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { productsService } from '../../../api/service.products';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatWeight } from '../../../utils/format';
import { DialogParams } from '../../elements/bp-dialog';

class AdminReports {
  title: Observable<string>;
  products: ObservableArray<any>;


  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;

  filter = ko.observable('');

  constructor(params: any) {
    this.title = ko.observable('Admin / Reports');
    this.products = ko.observableArray();

    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.loadProducts();
  }

  loadProducts = () => {
    productsService
      .getProductionReport()
      .then(products => {
        const productMap = products.map((p: any) => {

          const manufacturedQuantity = p.weeklyAverage > 0 ? (p.weeklyAverage / p.unitWeight) : 0;
          const rounded = manufacturedQuantity > 0 && p.unitWeight > 0 ? (Math.round(manufacturedQuantity * 100) / 100).toFixed(0) : 0;

          return ({
            ...p,
            formattedUnitWeight: formatWeight(p.unitWeight),
            formattedWeeklyAverage: formatWeight(Math.round(p.weeklyAverage * 100) / 100),
            manufacturedQuantity: rounded,
          });
        });

        this.products(productMap.sort((a: any, b: any) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0));
      })
      .catch(e => console.log(e));
  }

  goto = {
    userDetails: (id: number): void => router.goto(routes.adminUserDetails.interpolate({ id: id })),
  }
}

export default {
  name: 'bp-admin-reports',
  viewModel: AdminReports,
  template: require('./reports.html')
};
